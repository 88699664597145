@import url(https://fonts.googleapis.com/css?family=M+PLUS+Rounded+1c|Noto+Serif+JP&display=swap);
html, body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: #fdfdfd;
  font-family: 'Helvetica Neue', arial, sans-serif;
  font-weight: 400;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  font-family: 'M PLUS Rounded 1c', 'Helvetica Neue', arial, sans-serif;
}

#app {
  height: 100%;
}

/**
 * this style sheet is cited from https://github.com/jonsuh/hamburgers
 */

.wrapper__-sbjV {
  position: relative;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger__jV4L3 {
  /*padding: 15px 15px;*/
  padding: 12px 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger__jV4L3:hover {
    opacity: 0.7; }
  .hamburger__jV4L3.isActive__dcdik:hover {
    opacity: 0.7; }
  .hamburger__jV4L3.isActive__dcdik .hamburgerInner__cf6tU,
  .hamburger__jV4L3.isActive__dcdik .hamburgerInner__cf6tU::before,
  .hamburger__jV4L3.isActive__dcdik .hamburgerInner__cf6tU::after {
    background-color: #000; }

.hamburgerBox__y8ihJ {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburgerInner__cf6tU {
  display: block;
  top: 50%;
  margin-top: 4px; }
  .hamburgerInner__cf6tU, .hamburgerInner__cf6tU::before, .hamburgerInner__cf6tU::after {
    width: 40px;
    height: 3px;
    background-color: #4d4d4d;
    /* border-radius: 4px; */
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburgerInner__cf6tU::before, .hamburgerInner__cf6tU::after {
    content: "";
    display: block; }
  .hamburgerInner__cf6tU::before {
    top: -12px; }
  .hamburgerInner__cf6tU::after {
    bottom: -12px; }


/*
 * Slider
 */
.hamburgerSlider__iZ\+8V .hamburgerInner__cf6tU {
  top: 2px; }
  .hamburgerSlider__iZ\+8V .hamburgerInner__cf6tU::before {
    top: 12px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburgerSlider__iZ\+8V .hamburgerInner__cf6tU::after {
    top: 24px; }

.hamburgerSlider__iZ\+8V.isActive__dcdik .hamburgerInner__cf6tU {
  transform: translate3d(0, 12px, 0) rotate(45deg); }
  .hamburgerSlider__iZ\+8V.isActive__dcdik .hamburgerInner__cf6tU::before {
    transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
    opacity: 0; }
  .hamburgerSlider__iZ\+8V.isActive__dcdik .hamburgerInner__cf6tU::after {
    transform: translate3d(0, -24px, 0) rotate(-90deg); }

/*
   * Slider Reverse
   */
.hamburgerSliderR__q22iT .hamburgerInner__cf6tU {
  top: 2px; }
  .hamburgerSliderR__q22iT .hamburgerInner__cf6tU::before {
    top: 12px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburgerSliderR__q22iT .hamburgerInner__cf6tU::after {
    top: 24px; }

.hamburgerSliderR__q22iT.isActive__dcdik .hamburgerInner__cf6tU {
  transform: translate3d(0, 12px, 0) rotate(-45deg); }
  .hamburgerSliderR__q22iT.isActive__dcdik .hamburgerInner__cf6tU::before {
    transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
    opacity: 0; }
  .hamburgerSliderR__q22iT.isActive__dcdik .hamburgerInner__cf6tU::after {
    transform: translate3d(0, -24px, 0) rotate(90deg); }


/*
 * Menu
 */
.menuWrapper__ZUpjO {
  position: absolute;
  min-width: 240px;
  right: 0;
  margin: 0 auto;
  z-index: 10;
  background-color: #fff;
  -ms-transform: scale(0);
      transform: scale(0);
  transition: transform 0.2s;
  -ms-transform-origin: top right;
      transform-origin: top right;
}

.menuWrapper__ZUpjO.isActive__dcdik {
  -ms-transform: scale(1);
      transform: scale(1);
}

.item__LCnyE {
  color: #000;
  display: block;
  padding: 20px;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 1px solid #bbb;
}

.button_root__0Azm9 {
  display: block;
  position: relative;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  vertical-align: middle;

  padding: 5px 15px;
  width: 100%;
  min-width: 36px;
  font-size: 0.875rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 6px;
  letter-spacing: 0.02857em;

  cursor: pointer;
  outline:0;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}


/* contained style */
.button_contained__AiATD {
  color: #fff;
  background-color: #1976d2;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  border: None;
}
.button_contained__AiATD:hover {
  background-color: rgb(17, 82, 147);

}

/* outlined style */
.button_outlined__VIsTB {
  border: 1px solid #000;
  background-color: transparent;
}


/* Ripple effect */
.ripple__c8uby {
  background-position: center;
  transition: background 1.2s;
}
.ripple__c8uby:hover {
  /*background: #47a7f5*/
  background: #ffffff
    radial-gradient(circle, transparent 1%, #ffffff 1%) center / 15000%;
}
.ripple__c8uby:active {
  background-color: #dddddd;
  background-size: 100%;
  transition: background 0s;
}


.wrapper__Dhy3G {
  position: relative;
}
.menu__27I5U {
  width: 100%;
  position: absolute;
  z-index: 99;
  -ms-transform-origin: 'top left';
      transform-origin: 'top left';
  -ms-transform: scale(0);
      transform: scale(0);
  transition: transform 0.2s;
}
.menu__27I5U.shown__pWYjO {
  -ms-transform: scale(1);
      transform: scale(1);
}

.itemsWrapper__\+N4GM {
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0;0;0;0.2);
  background-color: #fff;
  margin: 4px 0px;
}

.item__djOhq {
   color: #000;
   display: block;
   padding: 10px 30px;
   text-decoration: none;
   cursor: pointer;
   border-bottom: 1px solid #bbb;
   text-align: center;
}

.cellInner__Kzpz\+ {
  font-family: "Noto Serif JP";
}

table.recTable__0BXcG {
  font-size: 24px;
  width: 100%;
  text-align: end;
}
td.last__oHmRj {
  width: 50%;
  font-weight: bold;
}
/*
table.recTable td {
  border-bottom: 1px solid #222;
  padding: 2px;
}
*/

table.recTable__BK3rD {
  width: 100%;
  text-align: end;
}
td.head__jKfc0 {
  font-size: 16px;
}
td.last__\+OGpD {
  font-size: 24px;
  width: 75%;
  font-weight: bold;
}

.tableTitle__\+VUOQ {
  margin: 12px 0 0;
}

table.recTable__JQaVb {
  width: 100%;
  border-collapse: collapse;
}

td.head__lIzgy {
  width: 33%;
}
table.recTable__JQaVb td.mid__GkA8D {
  width: 30%;
  border-right: 0px;
}
table.recTable__JQaVb td.last__82ZY3 {
  border-left: 0px;
}

table.recTable__JQaVb td {
  border: 1px solid black;
  padding: 6px;
}

.scoreImage__\+TXBu {
  position: absolute;
  top: -10%;
  left: 10%;
  z-index: 1;
}

.charBoxWrapper__diw6n {
  text-decoration: none;
  color: #000;
}

.charBox__J1dgb {
  position: relative;
  min-width: 48px;
  padding: 4px;
  text-align: center;
  border: 1px solid #000;
  border-radius: 6px;
  font-size: 16px;
  font-family: "Noto Serif JP";
}


.image__5Crl- {
  width: 100%;
  border: 1px solid #000;
}
.score__AtYg- {
  text-align: center;
  margin: -6px 0px 8px;
}

