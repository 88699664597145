.scoreImage {
  position: absolute;
  top: -10%;
  left: 10%;
  z-index: 1;
}

.charBoxWrapper {
  text-decoration: none;
  color: #000;
}

.charBox {
  position: relative;
  min-width: 48px;
  padding: 4px;
  text-align: center;
  border: 1px solid #000;
  border-radius: 6px;
  font-size: 16px;
  font-family: "Noto Serif JP";
}
