.tableTitle {
  margin: 12px 0 0;
}

table.recTable {
  width: 100%;
  border-collapse: collapse;
}

td.head {
  width: 33%;
}
table.recTable td.mid {
  width: 30%;
  border-right: 0px;
}
table.recTable td.last {
  border-left: 0px;
}

table.recTable td {
  border: 1px solid black;
  padding: 6px;
}
