@import url('https://fonts.googleapis.com/css?family=M+PLUS+Rounded+1c|Noto+Serif+JP&display=swap');

html, body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: #fdfdfd;
  font-family: 'Helvetica Neue', arial, sans-serif;
  font-weight: 400;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  font-family: 'M PLUS Rounded 1c', 'Helvetica Neue', arial, sans-serif;
}

#app {
  height: 100%;
}
