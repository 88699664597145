.wrapper {
  position: relative;
}
.menu {
  width: 100%;
  position: absolute;
  z-index: 99;
  transform-origin: 'top left';
  transform: scale(0);
  transition: transform 0.2s;
}
.menu.shown {
  transform: scale(1);
}

.itemsWrapper {
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0;0;0;0.2);
  background-color: #fff;
  margin: 4px 0px;
}

.item {
   color: #000;
   display: block;
   padding: 10px 30px;
   text-decoration: none;
   cursor: pointer;
   border-bottom: 1px solid #bbb;
   text-align: center;
}
