table.recTable {
  width: 100%;
  text-align: end;
}
td.head {
  font-size: 16px;
}
td.last {
  font-size: 24px;
  width: 75%;
  font-weight: bold;
}
